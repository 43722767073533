@font-face {
  font-family: OutfitRegular;
  src: url(./../assets/font/Outfit-Regular.ttf) format("truetype");
}

@font-face {
  font-family: OutfitMedium;
  src: url(./../assets/font/Outfit-Medium.ttf) format("truetype");
}

@font-face {
  font-family: OutfitBold;
  src: url(./../assets/font/Outfit-Bold.ttf) format("truetype");
}

html * {
  font-family: OutfitRegular;
}

body {
  overflow-x: hidden;
  background-color: white;
  margin: 0;
}

.noScroll {
  height: 100px;
  min-height: 100px;
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
}

.hide {
  display: none;
}

.topVenue {
  height: 34px;
  margin-top: 12px;
  background-color: white;
}

.imgLogoTop {
  width: auto;
  height: 20px;
  margin-top: 6px;
}

.venuePrefixSpan {
  width: 34px;
  height: 34px;
}

.venueSpan {
  font-size: 14px;
  font-family: "OutfitBold";
  color: black;
  align-content: center;
  display: block;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrongLocationSpan {
  font-size: 14px;
  color: #53b1c0;
  font-family: "OutfitBold";
}

.wrongLocaltion {
  width: Auto;
  height: 34px;
  right: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requestBoldSpan {
  height: 100%;
  font-size: 18px;
  margin-left: 4px;
  margin-right: 4px;
  font-family: "OutfitBold";
  color: #262f31;
  align-content: center;
}

.requestedSpan {
  height: 100%;
  font-size: 18px;
  margin-left: 4px;
  margin-right: 4px;
  align-items: center;
  color: #262f31;
}

.bulletSpan {
  font-size: 18px;
  font-family: "OutfitBold";
  margin-left: 8px;
  margin-right: 8px;
  color: #262f31;
  display: inline-flex;
  align-items: center;
  text-align: center;
}

.noPadding {
  padding-left: 0;
  padding-right: 0;
}

.noMargin {
  margin-left: 0;
  margin-right: 0;
}

.person {
  color: #fff;
  width: 100%;
  height: 180px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.person1 {
  color: #fff;
  padding-bottom: 10px;
  position: relative;
}

.background {
  padding-top: 10px;
}

#venue {
  position: absolute;
  right: 0;
  top: 20px;
  height: 60px;
  color: white;
  font-size: 13px;
  margin-right: 20px;
}

#imgVenue {
  height: 14px !important;
  width: 11px !important;
  margin-top: 4px;
  margin-left: 6px;
}

.center {
  top: 60px;
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

.centerAMZ {
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

.name {
  font-size: 16px;
  font-family: "OutfitMedium";
  color: #000;
}

.songArtist {
  font-size: 14px;
  color: #919191;
  font-family: "OutfitRegular";
}

.songRequester {
  font-size: 14px;
  color: #919191;
  font-family: "OutfitBold";
}

.money {
  font-size: 13px;
  font-family: "OutfitBold";
}

.genre {
  font-size: 14px;
}

.address {
  width: 100%;
  height: 100%;
  font-size: 13px;
  font-family: "OutfitBold";
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.profile-img {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.profile-img img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.intro {
  padding: 0 20px;
  margin-top: 30px;
  text-align: center;
  background: #fff;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto, serif;
  font-size: 15px;
  font-weight: 300;
  padding: 0 11px 0 13px;
  z-index: 1000;
  text-overflow: ellipsis;
}

#pac-input:focus {
  border-color: #4d90fe;
}

.controls {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  padding: 15px;
  -webkit-appearance: none;
}

.phoneInput {
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  padding: 15px;
  -webkit-appearance: none;
}

#btnCloseLocation {
  margin-top: 10px;
}

.pac-container {
  z-index: 2000;
}

.noLocation {
  margin: 0 auto;
  padding-top: 20px;
}

.imageNoLocation {
  text-align: center;
  margin-top: 90px;
}

.imageNoLocation img {
  width: 45%;
  height: 20%;
}

.accept {
  padding-top: 25px;
  margin: 0 auto;
  text-align: center;
}

.accept span {
  padding: 14px 40px;
  border: 1px solid #1111113d;
  color: #fff;
  font-family: "OutfitBold";
  background-color: #111;
  border-radius: 25px;
}

.accept span:hover {
  cursor: pointer;
}

.textAllow {
  text-align: center;
}

.selectPlaylist {
  width: 100px;
  margin-top: 10px;
  font-size: 22px;
  font-family: "OutfitBold";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  color: #1e2129;
}

.songTitle {
  font-size: 14px;
  font-family: "OutfitBold";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  color: #1e2129;
}

.songThumb {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-top: 20px;
  align-items: center;
  align-content: center;
}

.songThumb img {
  width: 50px;
  height: 50px;
  // border-radius: 10%;
}

.songThumbSearch {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-top: 10px;
  align-items: center;
  align-content: center;
}

.songThumbSearch img {
  width: 50px;
  height: 50px;
  // border-radius: 10%;
}

.songThumbMarquee {
  width: 40px;
  height: 40px;
  border-radius: 10%;
}

.song {
  background-repeat: no-repeat;
  height: 70px;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 16px;
  // color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.songFirstFriday {
  background-repeat: no-repeat;
  height: 90px;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 16px;
  // color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.songSpotifySearch {
  background-repeat: no-repeat;
  height: 70px;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 16px;
  color: #fff;
  margin-left: 0;
  margin-right: 0;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.songRequest {
  background-repeat: no-repeat;
  height: 70px;
  width: 100%;
  margin-left: 5px;
  overflow: hidden;
  border-radius: 16px;

  align-items: center;
  align-content: center;
  color: #fff;
}

.songRequestThumb {
  width: 36px;
  height: 36px;
  margin-left: 10px;
  align-items: center;
  align-content: center;
}

.songRequestThumb img {
  width: 36px;
  height: 36px;
  border-radius: 25%;
}

.playlist {
  background-repeat: no-repeat;
  height: 135px;
  width: 110px;
  overflow: hidden;
  text-align: center;
  border-radius: 16px;
  border: 4px solid #ffffff00;
  color: #fff;
  position: relative;
}

.playlistActive {
  background-repeat: no-repeat;
  height: 135px;
  width: 110px;
  overflow: hidden;
  text-align: center;
  border-radius: 16px;
  border: 3px solid #53b1c0;
  color: #fff;
  position: relative;
}

.imgPlaylist,
.divPlaylist {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  left: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.divPlaylist {
  z-index: 5;
}

.txtPlaylist {
  width: 100%;
  position: absolute;
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  display: flex;
  align-items: center;
  top: 0;
  object-fit: cover;
  left: 0;
  color: #fff;
  z-index: 10;
}

.txtPlaylist span {
  position: absolute;
  left: 12px;
  right: 8px;
  text-align: start;
  font-size: 16px;
  font-family: "OutfitMedium";
  color: #fff;
}

.footer {
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
}

.footerFF {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
}

.fabTip {
  bottom: 60px;
  height: 48px;
  color: white;
  text-align: center;
  position: fixed;
}

.fabShare {
  width: 46px;
  height: 46px;
  background-color: #2b2b2b;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgb(184, 184, 184);
  transition: all 0.1s ease-in-out;
  color: white;
  position: fixed;
  right: 14px;
  bottom: 60px;
}

.fabShare img {
  width: 40px;
  height: 40px;
  margin-left: 4px;
  margin-top: 2px;
}

.fab:hover {
  box-shadow: 0 6px 14px 0 #666;
  transform: scale(1.05);
}

.song {
  margin-left: 0;
  margin-right: 0;
}

.textarea {
  width: 100%;
  height: 120px;
  padding: 4px;
  background-color: #fff;
  border-color: #1e212931;
  border-radius: 0.4em;
  color: #363636;
}

.textareaName {
  width: 100%;
  height: 36px;
  padding: 4px;
  background-color: #fff;
  border-color: #1e212931;
  border-radius: 0.4em;
  color: #363636;
}

.btnRequestShoutout {
  font-family: "OutfitBold";
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #0092e0;
  color: #fff;
  width: 160px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 6px 10px 0 #0092e0;
}

.btnNotify {
  font-family: "OutfitBold";
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 20px;
  background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.btnEmail {
  font-family: "OutfitBold";
  font-size: 16px;
  margin-left: 10px;
  padding-left: 0px;
  padding-right: 0px;
  background: linear-gradient(99.72deg, #ffb84e -3.4%, #fea623 103.88%);
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.btnPhoneNumber {
  font-family: "OutfitBold";
  font-size: 16px;
  margin-right: 10px;
  padding-left: 0px;
  padding-right: 0px;
  background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.searchNotes {
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.03;
  letter-spacing: normal;
  text-align: left;
  color: #55c053;
}

.btnCancelSongRequest {
  margin-top: 15px;
  background-color: #ffffff00;
  color: #1e2129;
  width: 160px;
  opacity: 0.35;
  height: 40px;
}

.btnCancelRequest {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #f3f3f3;
  color: #000;
  width: 100%;
  height: 44px;
  border-radius: 8px;
}

.btnMaybeLater {
  margin-top: 20px;
  background-color: #ffffff00;
  color: #909090;
  width: 100%;
  height: 30px;
  font-size: 14px;
}

.btnUsernameSkip {
  background-color: #ffffff00;
  color: #000;
  width: 200px;
  height: 30px;
  margin-bottom: 12px;
  font-size: 12px;
}

.btnGetCoin {
  font-family: "OutfitBold";
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #0092e0;
  color: #fff;
  width: 280px;
  height: 40px;
  font-size: 13px;
  border-radius: 20px;
  box-shadow: 0 6px 10px 0 #0092e0;
}

.btnCoinText {
  font-family: "OutfitBold";
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #2080c9;
  color: #fff;
  width: 280px;
  height: 40px;
  font-size: 13px;
  border-radius: 20px;
  box-shadow: 0 6px 10px 0 #2080c977;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 1rem;
  outline: 0;
}

.modal-body-custom {
  position: relative;
  flex: 1 1 auto;
  padding: 0;
}

.songThumbRequested {
  width: 200px;
  height: 200px;
  margin-top: 10px;
  align-items: center;
  align-content: center;
}

.songThumbRequested img {
  width: 200px;
  height: 200px;
  box-shadow: 0 6px 10px 0 rgb(184, 184, 184);
  // border-radius: 10%;
}

.djAvatar {
  background-image: url("/assets/img/ic_default_user.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 90px;
  height: 90px;
  object-fit: none;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
}

.djAvatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.progressTime bar {
  background-color: #0092e0;
}

.marginTop {
  margin-top: 20px;
}

.fullWidth {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.tipBlock {
  width: 100%;
  margin-top: 20px;
}

.tipItem {
  margin-left: 0;
  margin-right: 0;
  height: 140px;
  width: 100%;
}

.rewardItem {
  margin-left: 0;
  margin-right: 0;
  height: 140px;
  width: 90px;
}

.rewardItem img {
  height: 70px;
  width: 70px;
}

.donateItem {
  margin-left: 0;
  margin-right: 0;
  height: 140px;
  width: 100%;
}

.moneyText {
  font-size: 16px;
  font-family: "OutfitRegular";
  color: #53b1c0;
}

.resetMargin {
  margin-left: 0;
  margin-right: 0;
}

.songSearch {
  width: 40px;
  height: 40px;
}

.songSearch img {
  width: 26px;
  height: 26px;
  right: 20px;
  position: absolute;
  margin-top: 3px;
}

.songSearchResult {
  margin-top: 5px;
  margin-bottom: 10px;
  height: 400px;
  width: 100%;
}

.songSearchDisclaimer {
  width: 100%;
  color: gray;
  margin: 10px;
  font-size: 15px;
}

.songSearchResult ul {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  margin-left: 20px;
  margin-right: 20px;
}

.popupTitle {
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
  margin-top: 20px;
}

.popupTxtTitle {
  margin-left: 20px;
  width: 100%;
  font-family: "OutfitBold";
  margin-top: 20px;
}

div.horizontal {
  display: flex;
  width: 100%;
  height: 170px;
  overflow: auto;
  flex-wrap: nowrap;
}

div.horizontal .table {
  display: table;
  table-layout: fixed;
  width: 110px;
}

div.horizontal .table article {
  width: 110px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.btnShare {
  width: 60px;
  height: 60px;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 4px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 3px 8px;
  border-radius: 30px;
}

.btnShare fa-icon {
  color: #fff;
}

hr.solid {
  width: 100%;
  border-top: 1px solid #bbb;
}

.loginBtn {
  width: 100%;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  margin-left: 20px;
  margin-right: 20px;
  display: inline-block;
  font-size: 15px;
  line-height: 40px;
}

.fb {
  background-color: #3b5998;
  color: white;
}

.google {
  background-color: #dd4b39;
  color: white;
}

.sms {
  background-color: #2080c9;
  color: white;
}

#marquee {
  position: relative;
  width: 100%;
  height: 50px;
  background: transparent;
  overflow: hidden;
}

#marquee::before {
  content: " ";
  white-space: pre;
}

#marqueeP {
  position: absolute;
  top: 0;
  margin: 0;
  white-space: nowrap;
  animation: sidescroll 120s linear infinite;
}

@keyframes sidescroll {
  0% {
    left: 100%;
    transform: translateX(0);
  }

  100% {
    left: 0;
    transform: translateX(-100%);
  }
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center-crop {
  object-fit: cover;
}

.user-maybe-later {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
}

.sponsor-banner {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}

.sponsor-banner img {
  width: 100%;
  height: 160px;
  border: none;
  border-radius: 14px;
  display: inline-block;
}

.coin-text {
  font-size: 14px;
  font-family: "OutfitBold";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: right;
  color: #36ce87;
}

#apple-pay-button {
  height: 48px;
  width: 100%;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
}

.menu-modal {
  position: absolute;
  top: 10px;
  width: 200px;
  height: 220px;
  bottom: 0;
  right: 0;
  z-index: 10040;
  overflow: auto;
  overflow-y: auto;
}

.menu-firstfriday-modal {
  position: absolute;
  top: 10px;
  width: 200px;
  height: 180px;
  bottom: 0;
  right: 0;
  z-index: 10040;
  overflow: auto;
  overflow-y: auto;
}

.modal-custom-lg .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
}

.modal-custom-lg .modal-content {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
}

.budlight-modal {
  background-image: url("/assets/img/img_budlight_new_bg.jpg");
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.budlightContent {
  height: 380px;
  background-color: #ffffffcc;
  border-radius: 20px;
  margin: 20px;
  padding: 20px;
}

.btnBudlightText {
  margin-top: 30px;
  background-color: #23559c;
  color: white;
  width: 160px;
  height: 60px;
  font-family: "OutfitBold";
  border: solid 1px #ffffff;
  border-radius: 20px;
}

.budlight-center {
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

.budlight-end {
  display: flex;
  justify-content: flex-end;
}

.btnBudlightMaybeLater {
  margin-top: 10px;
  background-color: #ffffff00;
  color: #23559cb6;
  width: 120px;
  height: 30px;
  font-size: 12px;
  font-family: "OutfitBold";
}

.item-footer-info {
  width: 60px;
  color: gray;
}

.mainItem {
  background-repeat: no-repeat;
  height: 100px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  overflow: hidden;
  border-radius: 16px;
  color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.mainItemKaraoke {
  background-repeat: no-repeat;
  height: 250px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  overflow: hidden;
  border-radius: 16px;
  color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.mainTitle {
  font-size: 16px;
  color: #000000;
  display: block;
  text-align: left;
  font-family: "OutfitBold";
}

.mainSubtitle {
  font-size: 12px;
  color: #919191;
  display: block;
  text-align: left;
}

.mainThumb {
  width: 60px;
  height: 60px;
  margin-left: 20px;
  margin-top: 20px;
  align-items: center;
  align-content: center;
}

.mainThumb img {
  width: 60px;
  height: 60px;
}

.mainTitleKaraoke {
  font-size: 20px;
  color: #000000;
  display: block;
  text-align: center;
  font-family: "OutfitBold";
}

.mainSubtitleKaraoke {
  font-size: 16px;
  color: #919191;
  display: block;
  text-align: center;
}

.mainThumbKaraoke {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
  align-content: center;
}

.mainThumbKaraoke img {
  width: 60px;
  height: 60px;
}

.btnTip {
  background: linear-gradient(99.72deg, #ffb84e -3.4%, #fea623 103.88%);
  border-radius: 8px;
  align-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  min-width: 40px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 4px;
}

.btnTip img {
  width: 36px;
  height: 36px;
}

.btnTip span {
  font-size: 16px;
  color: #ffffff;
  font-family: "OutfitBold";
}

.btnMenu {
  border-radius: 8px;
  align-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  padding-left: 0px;
  padding-right: 10px;
}

.btnMenu img {
  margin-top: 3px;
  width: 32px;
  height: 32px;
}

.btnFollow {
  background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
  border-radius: 8px;
  align-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  padding-left: 0px;
  padding-right: 0px;
}

.btnFollow img {
  margin-top: 3px;
  width: 32px;
  height: 32px;
}

.btnFollow span {
  font-size: 16px;
  color: #ffffff;
  font-family: "OutfitBold";
}

.txtSearch {
  height: 48px;
  margin-top: 20px;
  left: 0;
  right: 0;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid rgb(153, 148, 148);
  border-radius: 6px;
}

.txtSearch textarea {
  height: 36px;
  margin-left: 10px;
  margin-top: 8px;
  display: table-cell;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

.genre {
  background-repeat: no-repeat;
  height: 50px;
  padding-left: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 16px;
  color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.checkingGenreThumb {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  align-content: center;
}

.premiumAvatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  align-content: center;
}

.bpmSliderTitle {
  font-size: 40px;
  margin-top: 10px;
  font-family: "OutfitBold";
  line-height: 1.61;
  letter-spacing: normal;
  line-height: 56px;
  color: #1e2129;
}

.bpmSliderSubtitle {
  font-size: 16px;
  line-height: 1.61;
  letter-spacing: normal;
  font-family: "OutfitRegular";
  line-height: 24px;
  color: #919191;
}

/*#region BPM Slider*/

.slider {
  -webkit-appearance: none;
  position: absolute;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  right: 0;
  left: 0;
  height: 12px;
  background-image: linear-gradient(270deg, #3298a8 0%, #61d4e7 100%);
  outline: none;
  border-radius: 10px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: 0px 0px 0px 0px white;
  width: 50px;
  height: 50px;
  background-color: transparent;
  background-image: url("/assets/img/ic_slider_thumb.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

/*#endregion */

@media only screen and (max-height: 667px) {
  .fabBPMRequest {
    left: 10px;
    right: 10px;
    bottom: 10px;
    height: 48px;
    background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
    border-radius: 8px;
    color: white;
    align-items: center;
    position: fixed;
    display: flex;
    justify-content: center;
  }

  .adBPMRequest {
    bottom: 80px;
    height: 50px;
    width: 320px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-height: 668px) {
  .fabBPMRequest {
    left: 10px;
    right: 10px;
    bottom: 60px;
    height: 48px;
    background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
    border-radius: 8px;
    color: white;
    align-items: center;
    position: fixed;
    display: flex;
    justify-content: center;
  }

  .adBPMRequest {
    bottom: 120px;
    height: 50px;
    width: 320px;
    position: fixed;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
  }
}

.fabBPMRequest span {
  height: 48px;
  text-align: center;
  line-height: 24px;
  display: flex;
  font-size: 16px;
  color: #ffffff;
  font-family: "OutfitBold";
  align-items: center;
}

.pd_group {
  background-repeat: no-repeat;
  height: Auto;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 16px;
  color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.pd_title {
  width: 100px;
  margin-top: 10px;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 20px;
  font-family: "OutfitBold";
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #1e2129;
}

.pd_title_genre {
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  margin-top: 10px;
  font-family: "OutfitBold";
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: #1e2129;
}

.centerItem {
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

.locationModal_title {
  font-size: 16px;
  font-family: "OutfitBold";
  color: #000;
}

.locationModal_address {
  font-size: 12px;
  color: #919191;
  display: block;
  text-align: left;
}

.tipModal {
  padding-left: 10px;
  padding-right: 10px;
}

.titleTipModal {
  font-family: "OutfitRegular";
  color: #262f31;
  font-size: 16px;
}

.tipModal h5 {
  margin-top: 10px;
  color: #262f31;
}

.tipModalCustomTip {
  font-size: 16px;
  margin-top: 20px;
  color: #262f31;
  margin-left: 20px;
  font-family: "OutfitMedium";
}

.tipModalCustomTipText {
  height: 44px;
  padding: 4px;
  background-color: #fff;
  border-color: #1e212931;
  border-radius: 0.4em;
  font-size: 16px;
  margin-top: 10px;
  color: #262f31;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "OutfitRegular";
}

.checkingShoutoutRequestModal {
  padding-left: 10px;
  padding-right: 10px;
}

.checkingShoutoutRequestModal h4 {
  font-family: "OutfitBold";
  color: #262f31;
}

.checkingShoutoutRequestModal h6 {
  margin-top: 10px;
  color: #262f31;
}

.checkingShoutoutRequestModalShoutoutMessage {
  font-family: "OutfitBold";
  margin-top: 15px;
}

.genreViewport {
  left: 10px;
  right: 10px;
  position: fixed;
  top: 80px;
  bottom: 30px;
}

.genreItem {
  margin-left: -6px;
  padding-left: 0px;
  padding-right: 0px;
  align-content: center;
  align-items: center;
}

.btnTipCustomTip {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  height: 48px;
  background: linear-gradient(99.72deg, #ffb84e -3.4%, #fea623 103.88%);
  border-radius: 8px;
  align-content: center;
  align-items: center;
  text-align: center;
}

.btnTipCustomTip img {
  width: 48px;
  height: 48px;
}

.btnTipCustomTip span {
  font-size: 16px;
  color: #ffffff;
  font-family: "OutfitBold";
}

.mainViewport {
  left: 10px;
  right: 10px;
  position: fixed;
  top: 85px;
  bottom: 120px;
}

.checkingModal {
  padding-left: 10px;
  padding-right: 10px;
}

.checkingModal h4 {
  width: 100%;
  font-family: "OutfitBold";
}

.checkingModal h6 {
  width: 100%;
  margin-top: 15px;
  font-family: "OutfitRegular";
}

.checkingModal span {
  width: 100%;
  font-family: "OutfitBold";
  margin-top: 15px;
}

.askLocationModal {
  padding-left: 10px;
  padding-right: 10px;
}

.askLocationModal h4 {
  font-family: "OutfitBold";
  color: #262f31;
  text-align: start;
}

.askLocationModal h6 {
  font-family: "OutfitRegular";
  color: #262f31;
  text-align: start;
}

.googleAd {
  width: 300px;
  height: 0px;
}

.googleAd2 {
  width: 320px;
  height: 0px;
}

.btnMenu {
  text-align: left;
  height: 44px;
  margin-left: 15px;
  font-weight: 500;
  width: 100%;
  color: #262f31;
  font-family: "OutfitRegular";
}

.btnHelpRequest {
  font-family: "OutfitBold";
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
  background: #f3f3f3;
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

form.nosubmit {
  border: none;
  padding: 0;
}

input.nosubmit {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 8px;
  display: block;
  padding: 9px 4px 9px 40px;
  color: #000000;
  background-image: url("/assets/img/ic_search.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 12px;
  background-size: 20px;
}

.imgThumbUp {
  width: auto;
  height: 15px;
  padding-right: 14px;
}

.songThumbUpvote {
  width: 130px;
  height: 130px;
  margin-top: 10px;
  align-items: center;
  align-content: center;
}

.songThumbUpvote img {
  width: 130px;
  height: 130px;
  box-shadow: 0 6px 10px 0 rgb(184, 184, 184);
  // border-radius: 10%;
}

.btnUpvote {
  font-family: "OutfitRegular";
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  background: white;
  border-radius: 12px;
  color: #fff;
  width: 100%;
  height: 60px;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.btnUpvote img {
  width: auto;
  height: 25px;
  margin-right: 4px;
}

.search {
  text-align: center;
  padding: 0px;
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.helpModelBullet {
  font-size: 18px;
  color: #53b1c0;
  font-family: OutfitBold;
}

.helpModelBulletContent {
  font-size: 18px;
  color: #262f31;
}

.helpModelButton {
  font-weight: 600;
  font-family: OutfitRegular;
}

.btnSearch {
  text-align: left;
  padding-left: 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dddddd;
  width: 100%;
  height: 40px;
  color: #909090;
  font-size: 14px;
  cursor: pointer;
}

.btnSearch i {
  padding-right: 10px;
}

.btnSearch:active {
  background-color: lightgray;
}

.customTip {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border-color: lightgray;
}

.btnStandardPlaylist {
  align-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  min-width: 40px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 4px;
}

.btnStandardPlaylist img {
  width: 36px;
  height: 36px;
}

.btnLocationContinue {
  margin-left: 20px;
  margin-right: 20px;
  background-image: linear-gradient(270deg, #3298a8 0%, #61d4e7 100%);
  color: #fff;
  width: 100%;
  height: 44px;
  border-radius: 8px;
}

.centerLocation {
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
}

.locationResult {
  margin-top: 5px;
  margin-bottom: 10px;
  height: 240px;
  width: 100%;
}

.locationResult ul {
  height: 240px;
  overflow: hidden;
  overflow-y: scroll;
}

.currentLocation {
  background-repeat: no-repeat;
  width: 92%;
  margin-left: 15px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  border-radius: 16px;
  color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.currentLocation span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.btnLocationContinue {
  font-family: "OutfitBold";
  font-size: 16px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
  background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.centerImage {
  margin: auto 10px 12px 10px;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
}

.centerLocationCheck {
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

.btnSpotifyLogo {
  align-content: center;
  align-items: center;
  text-align: center;
  height: 24px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 15px;
}

.btnSpotifyLogo img {
  width: 24px;
  height: 24px;
  margin-left: 6px;
}

.btnSpotifyLogo span {
  color: #909090;
  font-size: 12px;
}

.btnSpotifyMenu {
  height: 40px;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.centerVertical {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btnSpotifyMenu img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.btnSpotifyMenu span {
  color: black;
  font-size: 0.875rem;
}
