// If you want to override variables do it here
@import "variables";

// Import styles
@import "../../lib/@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";

@import '~ngx-sharebuttons/themes/default/default-theme';
@import '~@angular/cdk/overlay-prebuilt.css';
@import "./mysqpaymentform.scss";

@import '~intl-tel-input/build/css/intlTelInput.css';